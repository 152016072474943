import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout' 
import ReactMarkdown from 'react-markdown'
import StarRatingComponent from 'react-star-rating-component';

const RezensionTemplate = ({ data }) => (
    <Layout>
<div className="px-4">

    <div className="flex flex-wrap">
        
        <div className="w-full md:w-1/4 xl:w-1/4 bg-gray-200 dark:bg-gray-700 p-4 text-center text-gray-200">
     
        <Img fluid={data.strapiRezension.coverimg.childImageSharp.fluid}/>
        <a className="book-links border-2 border-orange-300 dark:bg-blue-200" href={data.strapiRezension.amazon} target="_blank" rel="noopener noreferrer">
            <svg className="fill-current w-6 h-6 mr-2 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">    <path d="M12.14,2C9.513,2,6.595,2.983,5.981,6.196C5.915,6.538,5.898,6.718,5.898,6.718l3.58,0.362c0,0,0.02-0.291,0.068-0.538 c0.23-1.107,1.167-1.654,2.219-1.654c0.568,0,1.21,0.213,1.546,0.716c0.388,0.562,0.324,1.324,0.324,1.975v0.37 C12.035,8.127,9.947,8.236,8.45,8.887c-1.73,0.74-2.942,2.249-2.942,4.468c0,2.839,1.815,4.27,4.139,4.27 c1.963,0,3.023-0.471,4.538-1.999c0.501,0.718,0.679,1.072,1.596,1.827l0.299,0.251l0.349-0.3c0.551-0.486,1.556-1.358,2.119-1.827 c0.183-0.149,0.339-0.289,0.339-0.289s-0.306-0.381-0.339-0.427c-0.505-0.69-1.022-1.262-1.022-2.543V8.072 c0-1.804,0.121-3.473-1.222-4.715C15.244,2.351,13.482,2,12.14,2z M12.888,10.146c0.249-0.016,0.498,0,0.748,0v0.592 c0,1.065,0.043,1.941-0.499,2.888c-0.439,0.769-1.145,1.234-1.92,1.234c-1.059,0-1.671-0.792-1.671-1.975 C9.546,10.85,11.146,10.259,12.888,10.146z M20.517,16.515c-0.699,0.01-1.518,0.157-2.144,0.592 c-0.192,0.134-0.395,0.327-0.395,0.327s2.708-0.312,2.988,0.043c0.28,0.355-0.749,2.858-0.749,2.858s0.27-0.117,0.45-0.267 c1.173-0.972,1.487-2.988,1.247-3.283C21.795,16.641,21.217,16.505,20.517,16.515z M2,17c2.39,2.474,6.173,4,10,4 c2.73,0,5.657-0.911,7.844-2.509c0.304-0.233,0.535-0.474,0.675-0.698l-0.786,0.299c-2.452,1.03-5.12,1.434-7.544,1.434 C8.596,19.526,4.813,18.621,2,17L2,17z"/></svg>
            <span className="text-orange-500">Amazon</span>
        </a>
        
        <a className="book-links border-2 border-green-300 dark:bg-blue-200" href={data.strapiRezension.tahlia} target="_blank" rel="noopener noreferrer">
            
            <svg className="fill-current w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" >
        <defs/>
        <path fill="#00A04B" fill-rule="nonzero" d="M5.257 10.576c1.714.163 4.343-.162 7.276-1.383.572-.244 1.22-.529 2.02-.773 2.247-.732 2.666-.528 2.666-.528-.19-.61-.914-.57-2.4-.245-1.486.285-2.248.773-3.771.977-1.524.203-3.01-1.22-2.781-2.807 0 0 2.019.773 4.419-.407-.838 0-1.562-.366-1.257-1.668 1.6 1.75 3.504-.61 5.142-2.034.19-.162.381-.325.61-.447.229-.163.457-.285.686-.366.038-.04.114-.04.19-.081s1.029-.448 2.286-.407c.343 0 .647.081.952.325.153.163.115.285.115.407.8.122 1.409 1.261 1.409 2.034a5.5 5.5 0 00.952.732c.039.04.039.04.039.122 0 .204-.153.448-.305.57-.038 0-.038.04 0 .081l.038.04c.305.123.19.367.038.448.152.081.19.244.076.366a.317.317 0 00-.076.163c0 .122.19.163.229.407.038.203.038.366-.153.57-.114.121-.305.162-.495.162-.495.04-.99.04-1.676.529-.343.284-.534.57-.648.854a1.275 1.275 0 00-.076.407c-.114.813.076 1.342.114 1.423v.041c.457 1.546-.266 3.62-2.362 5.573-1.143 1.058-4.685 4.15-3.962 8.258 0 0-7.962-2.888-5.714-6.224.648-.976 2.02-1.587 3.62-2.36l.113-.04c1.677-.814 2.82-2.36 2.21-3.336-.152-.203-.343-.244-.533-.203-1.715.407-6.362.325-8.99.04-.268-.04-.534-.04-.763-.08h-.343c-.495 0-1.028-.082-1.447-.326-.305-.244-.648-.407-1.181-.529-.267-.04-.61-.081-.61-.285.038-.488.838-.081 1.372-.122.038 0 .038-.081-.038-.122-1.029-.447-1.6-.976-2.21-1.83-.076-.163.114-.244.152-.204 1.181 1.099 2.477 1.709 4.039 2.075.19.081.723.163 1.028.203z"/>
        </svg>
                
                <span className="text-blue-800">Thalia</span>
                </a>
                <a className="book-links border-2 border-red-300 dark:bg-blue-200" href={data.strapiRezension.hugendubel} target="_blank" rel="noopener noreferrer">
            
        
                
                <span className="text-red-600">Hugendubel</span>
                </a>
         

    </div>
    <div className="w-full md:w-3/4 xl:w-3/4 bg-gray-100 dark:bg-gray-800 p-4 text-left text-gray-700 dark:text-gray-200">
        <h2 className="rezension-title dark:text-gray-200">{data.strapiRezension.titel}</h2>
        <h2 className="rezension-list dark:text-gray-200">Autor: <Link className="rezension-info dark:text-joker-200 dark:bg-joker-800" to={`/autors/${data.strapiRezension.autor.slug}`}>{data.strapiRezension.autor.autorname}</Link></h2>
        <h2 className="rezension-list dark:text-gray-200">Genre: <span className="text-black dark:text-gray-500">{data.strapiRezension.genre}</span></h2>
        <h2 className="rezension-list dark:text-gray-200">Erschienen: <span className="text-black dark:text-gray-500">{data.strapiRezension.erschienen}</span></h2>
        <h2 className="rezension-list dark:text-gray-200">Verlag: <span className="text-black dark:text-gray-500">{data.strapiRezension.verlag}</span></h2>
        <h2 className="rezension-list dark:text-gray-200">Seiten: <span className="text-black dark:text-gray-500">{data.strapiRezension.seiten}</span></h2>
        <h2 className="rezension-list dark:text-gray-200">Buchformat: <span className="text-black dark:text-gray-500">{data.strapiRezension.buchformat}</span></h2>

        <h2 className="text-joker font-semibold mt-8 dark:text-gray-200">Klappentext</h2>
            <ReactMarkdown source={data.strapiRezension.klappentext}
                transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}
            />
   

    </div>
        <h2 className="text-joker font-semibold mt-8 dark:text-gray-200">Rezension</h2>
     <ReactMarkdown source={data.strapiRezension.rezension}
        transformImageUri={uri => uri.startsWith('http') ? uri : `${process.env.IMAGE_BASE_URL}${uri}`}
      />
     
  </div>
  <div className="text-center mt-6">
  <h2 className="text-joker font-semibold mt-8 mb-5 dark:text-gray-200">Lese Joker Punkte</h2>
  <StarRatingComponent 
      className="flex items-center"
            name="rate2" 
            editing={false}
            renderStarIcon={(index, value)=> {
                if (index <= value){
                    return (
                    <span ><svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="48" height="72">
                                <defs/>
                                <g fill="none" fill-rule="nonzero">
                                  <path fill="#353535" d="M12.1 12.6c-2.2 0-4.1 1.9-4.1 4.1 0 2.2 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1-1.8-4.1-4.1-4.1z"/>
                                  <path fill="#353535" d="M13.5 20.7h-2.8c-1 0-1.9.2-2.7.7 1.4.4 2.8.9 4.1 1.7 1.3-.8 2.7-1.3 4.1-1.7-.8-.4-1.8-.7-2.7-.7zM21 26.2v2.6c0 .7.6 1.4 1.3 1.4.8 0 1.4-.6 1.4-1.3v-2.7c0-.7-.6-1.4-1.4-1.3-.7 0-1.3.6-1.3 1.3z"/>
                                  <path fill="#7055A0" d="M.6 26.2v2.6c0 .7.6 1.4 1.3 1.4.8 0 1.4-.6 1.4-1.3v-2.7c0-.7-.6-1.4-1.4-1.3-.7 0-1.3.6-1.3 1.3z"/>
                                  <path fill="#7055A0" d="M2.6 22.1c-.4 0-.7.3-.7.7v.7c1.5 0 2.7 1.2 2.7 2.7v2.7c0 1.5-1.2 2.7-2.7 2.7v.7c0 .4.3.7.7.7 3.5 0 6.2 1.1 8.8 2.7V24.3c-2.5-1.5-5.3-2.2-8.8-2.2z"/>
                                  <path fill="#353535" d="M22.3 23.5v-.7c0-.4-.3-.7-.7-.7-3.4 0-6.2.7-8.8 2.2v11.4c2.6-1.7 5.4-2.7 8.8-2.7.4 0 .7-.3.7-.7v-.7c-1.5 0-2.7-1.2-2.7-2.7v-2.7c0-1.5 1.2-2.7 2.7-2.7z"/>
                                  <g transform="translate(3)">
                                    <path fill="#53B04A" d="M10.6 6.5c-.5-2.4-1.9-3.8-4.2-4.8-.3-.1-.6.3-.4.5.7.9 1.1 2 1.1 3.2 0 .4-.1.7-.2 1v2h3.7V6.5z"/>
                                    <path fill="#2CA860" d="M10.5 5.9c-.3.1-.5.2-.8.3-.2.1-.4 0-.4-.2C8.9 4.1 7.7 2.8 6 1.9c0 .1 0 .2.1.3.7.9 1.1 2 1.1 3.2 0 .4-.1.7-.2 1v1.8h1.3v.2h2.3V6.5c0-.2-.1-.4-.1-.6z"/>
                                    <path fill="#EA4F5C" d="M12 10.1v4.8H8.9V7.5c2-1.6 4.8-1.8 7-.8.4.2.3.7-.1.7-1 .1-2 .5-2.8 1.2-.4.4-.8.9-1 1.5"/>
                                    <path fill="#E83B65" d="M11.8 7.5l-.6.3c-.4.3-.9.2-1.2-.2-.2-.2-.4-.4-.6-.5-.2.1-.3.2-.5.4v7h1v.4H12v-4.8c.2-.5.5-1 1-1.5.8-.7 1.8-1.1 2.8-1.2.2 0 .3-.1.3-.2-1.5-.5-3-.3-4.3.3z"/>
                                    <path fill="#C763A3" d="M10.8 10.7C10.5 9.3 9.7 8 8.4 7.1c-2-1.3-4.5-1.2-6.4.1-.3.2-.1.6.2.6.8-.1 1.7.1 2.4.5s1.1 1 1.3 1.6l.1.9v4h4.8v-4.1"/>
                                    <path fill="#D25198" d="M10.6 9.8c-.5.3-.9.7-1.3 1.2-.1.1-.3.2-.4.1l-.2-.2C8.5 9.9 8 9 7.3 8.2c-1.4-1.5-3.7-1.4-5.4-.6 0 .1.2.2.3.2.8-.1 1.7.1 2.4.5s1.1 1 1.3 1.6l.1.9v4h4.9v-4.2c-.1-.2-.2-.5-.3-.8z"/>
                                    <path fill="#674897" d="M5.6 14.9h3.5v-2.5c0-.2-.1-.4-.2-.6-3.4-3.5-7.4-1.7-8.1 1.4 0 .1.1.1.1.1.5-.7 1.3-1.1 2.2-1.1 1.6-.1 2.5 1.2 2.5 2.7z"/>
                                    <path fill="#7055A0" d="M4.1 11.3c-1.1 0-2.2.6-2.9 1.2.1.1.3.2.4.1.4-.3 1-.5 1.5-.5 1.5 0 2.5 1.2 2.5 2.8h1.8c.1-2.2-1.3-3.6-3.3-3.6z"/>
                                    <path fill="#53B04A" d="M12.7 14.9H9.2v-2.5c0-.2.1-.4.2-.6 3.4-3.5 7.4-1.7 8.1 1.4 0 .1-.1.1-.1.1-.5-.7-1.3-1.1-2.2-1.1-1.6-.1-2.5 1.2-2.5 2.7z"/>
                                    <path fill="#2CA860" d="M13.8 11.1c-2.1.4-3 1.7-3.2 3.8h2c0-1.5.9-2.8 2.5-2.8.6 0 1.1.2 1.5.5.1.1.3.1.4 0-.8-.9-1.9-1.7-3.2-1.5z"/>
                                    <g fill="#FFDB6D" transform="translate(0 7)">
                                      <circle cx=".8" cy="6" r="1"/>
                                      <circle cx="1.3" cy=".7" r="1"/>
                                    </g>
                                    <g fill="#F9B86A">
                                      <path d="M1.3 7.1c.2.1.3.3.3.6 0 .4-.3.7-.7.7h.4c.4 0 .7-.3.7-.7-.1-.3-.4-.6-.7-.6zM.8 12.4c.2.1.3.3.3.6 0 .4-.3.7-.7.7.1.1.2.1.4.1.4 0 .7-.3.7-.7-.1-.4-.4-.7-.7-.7z"/>
                                    </g>
                                    <circle cx="5.5" cy="1.5" r="1" fill="#FFDB6D"/>
                                    <path fill="#F9B86A" d="M5.5.8c.2.1.3.3.3.6s-.3.6-.7.6c.1.1.2.1.4.1.4 0 .7-.3.7-.7 0-.3-.3-.6-.7-.6z"/>
                                    <circle cx="16.7" cy="7.1" r="1" fill="#FFDB6D"/>
                                    <path fill="#F9B86A" d="M16.7 6.4c.2.1.3.3.3.6 0 .4-.3.7-.7.7.1.1.2.1.4.1.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7z"/>
                                    <circle cx="17.5" cy="13" r="1" fill="#FFDB6D"/>
                                    <path fill="#F9B86A" d="M17.5 12.3c.2.1.3.3.3.6 0 .4-.3.7-.7.7.1.1.2.1.4.1.4 0 .7-.3.7-.7 0-.4-.3-.6-.7-.7z"/>
                                    <path fill="#FFDB6D" d="M13 14.9H5.3c-.2 0-.4.2-.4.4v1.2c0 .2.2.4.4.4H13c.2 0 .4-.2.4-.4v-1.2c0-.2-.2-.4-.4-.4z"/>
                                    <path fill="#F9B86A" d="M13 14.9h-1.2c.2 0 .4.2.4.4v1.1c0 .2-.2.4-.4.4H13c.2 0 .4-.2.4-.4v-1.2c0-.1-.2-.3-.4-.3z"/>
                                    <g fill="#7055A0" transform="translate(5 15)">
                                      <circle cx="1" cy=".9" r="1"/>
                                      <circle cx="2.2" cy=".9" r="1"/>
                                      <circle cx="3.4" cy=".9" r="1"/>
                                      <circle cx="4.6" cy=".9" r="1"/>
                                      <circle cx="5.9" cy=".9" r="1"/>
                                      <circle cx="7.1" cy=".9" r="1"/>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                              </span>
                    );
                    }
                    return ( <span>
                                        <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="48" height="72">
                      <defs/>
                      <g fill="none" fill-rule="nonzero" opacity=".276">
                        <path fill="#353535" d="M12.1 12.6c-2.2 0-4.1 1.9-4.1 4.1 0 2.2 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1-1.8-4.1-4.1-4.1z"/>
                        <path fill="#353535" d="M13.5 20.7h-2.8c-1 0-1.9.2-2.7.7 1.4.4 2.8.9 4.1 1.7 1.3-.8 2.7-1.3 4.1-1.7-.8-.4-1.8-.7-2.7-.7zM21 26.2v2.6c0 .7.6 1.4 1.3 1.4.8 0 1.4-.6 1.4-1.3v-2.7c0-.7-.6-1.4-1.4-1.3-.7 0-1.3.6-1.3 1.3z"/>
                        <path fill="#7055A0" d="M.6 26.2v2.6c0 .7.6 1.4 1.3 1.4.8 0 1.4-.6 1.4-1.3v-2.7c0-.7-.6-1.4-1.4-1.3-.7 0-1.3.6-1.3 1.3z"/>
                        <path fill="#7055A0" d="M2.6 22.1c-.4 0-.7.3-.7.7v.7c1.5 0 2.7 1.2 2.7 2.7v2.7c0 1.5-1.2 2.7-2.7 2.7v.7c0 .4.3.7.7.7 3.5 0 6.2 1.1 8.8 2.7V24.3c-2.5-1.5-5.3-2.2-8.8-2.2z"/>
                        <path fill="#353535" d="M22.3 23.5v-.7c0-.4-.3-.7-.7-.7-3.4 0-6.2.7-8.8 2.2v11.4c2.6-1.7 5.4-2.7 8.8-2.7.4 0 .7-.3.7-.7v-.7c-1.5 0-2.7-1.2-2.7-2.7v-2.7c0-1.5 1.2-2.7 2.7-2.7z"/>
                        <g transform="translate(3)">
                          <path fill="#53B04A" d="M10.6 6.5c-.5-2.4-1.9-3.8-4.2-4.8-.3-.1-.6.3-.4.5.7.9 1.1 2 1.1 3.2 0 .4-.1.7-.2 1v2h3.7V6.5z"/>
                          <path fill="#2CA860" d="M10.5 5.9c-.3.1-.5.2-.8.3-.2.1-.4 0-.4-.2C8.9 4.1 7.7 2.8 6 1.9c0 .1 0 .2.1.3.7.9 1.1 2 1.1 3.2 0 .4-.1.7-.2 1v1.8h1.3v.2h2.3V6.5c0-.2-.1-.4-.1-.6z"/>
                          <path fill="#EA4F5C" d="M12 10.1v4.8H8.9V7.5c2-1.6 4.8-1.8 7-.8.4.2.3.7-.1.7-1 .1-2 .5-2.8 1.2-.4.4-.8.9-1 1.5"/>
                          <path fill="#E83B65" d="M11.8 7.5l-.6.3c-.4.3-.9.2-1.2-.2-.2-.2-.4-.4-.6-.5-.2.1-.3.2-.5.4v7h1v.4H12v-4.8c.2-.5.5-1 1-1.5.8-.7 1.8-1.1 2.8-1.2.2 0 .3-.1.3-.2-1.5-.5-3-.3-4.3.3z"/>
                          <path fill="#C763A3" d="M10.8 10.7C10.5 9.3 9.7 8 8.4 7.1c-2-1.3-4.5-1.2-6.4.1-.3.2-.1.6.2.6.8-.1 1.7.1 2.4.5s1.1 1 1.3 1.6l.1.9v4h4.8v-4.1"/>
                          <path fill="#D25198" d="M10.6 9.8c-.5.3-.9.7-1.3 1.2-.1.1-.3.2-.4.1l-.2-.2C8.5 9.9 8 9 7.3 8.2c-1.4-1.5-3.7-1.4-5.4-.6 0 .1.2.2.3.2.8-.1 1.7.1 2.4.5s1.1 1 1.3 1.6l.1.9v4h4.9v-4.2c-.1-.2-.2-.5-.3-.8z"/>
                          <path fill="#674897" d="M5.6 14.9h3.5v-2.5c0-.2-.1-.4-.2-.6-3.4-3.5-7.4-1.7-8.1 1.4 0 .1.1.1.1.1.5-.7 1.3-1.1 2.2-1.1 1.6-.1 2.5 1.2 2.5 2.7z"/>
                          <path fill="#7055A0" d="M4.1 11.3c-1.1 0-2.2.6-2.9 1.2.1.1.3.2.4.1.4-.3 1-.5 1.5-.5 1.5 0 2.5 1.2 2.5 2.8h1.8c.1-2.2-1.3-3.6-3.3-3.6z"/>
                          <path fill="#53B04A" d="M12.7 14.9H9.2v-2.5c0-.2.1-.4.2-.6 3.4-3.5 7.4-1.7 8.1 1.4 0 .1-.1.1-.1.1-.5-.7-1.3-1.1-2.2-1.1-1.6-.1-2.5 1.2-2.5 2.7z"/>
                          <path fill="#2CA860" d="M13.8 11.1c-2.1.4-3 1.7-3.2 3.8h2c0-1.5.9-2.8 2.5-2.8.6 0 1.1.2 1.5.5.1.1.3.1.4 0-.8-.9-1.9-1.7-3.2-1.5z"/>
                          <g fill="#FFDB6D" transform="translate(0 7)">
                            <circle cx=".8" cy="6" r="1"/>
                            <circle cx="1.3" cy=".7" r="1"/>
                          </g>
                          <g fill="#F9B86A">
                            <path d="M1.3 7.1c.2.1.3.3.3.6 0 .4-.3.7-.7.7h.4c.4 0 .7-.3.7-.7-.1-.3-.4-.6-.7-.6zM.8 12.4c.2.1.3.3.3.6 0 .4-.3.7-.7.7.1.1.2.1.4.1.4 0 .7-.3.7-.7-.1-.4-.4-.7-.7-.7z"/>
                          </g>
                          <circle cx="5.5" cy="1.5" r="1" fill="#FFDB6D"/>
                          <path fill="#F9B86A" d="M5.5.8c.2.1.3.3.3.6s-.3.6-.7.6c.1.1.2.1.4.1.4 0 .7-.3.7-.7 0-.3-.3-.6-.7-.6z"/>
                          <circle cx="16.7" cy="7.1" r="1" fill="#FFDB6D"/>
                          <path fill="#F9B86A" d="M16.7 6.4c.2.1.3.3.3.6 0 .4-.3.7-.7.7.1.1.2.1.4.1.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7z"/>
                          <circle cx="17.5" cy="13" r="1" fill="#FFDB6D"/>
                          <path fill="#F9B86A" d="M17.5 12.3c.2.1.3.3.3.6 0 .4-.3.7-.7.7.1.1.2.1.4.1.4 0 .7-.3.7-.7 0-.4-.3-.6-.7-.7z"/>
                          <path fill="#FFDB6D" d="M13 14.9H5.3c-.2 0-.4.2-.4.4v1.2c0 .2.2.4.4.4H13c.2 0 .4-.2.4-.4v-1.2c0-.2-.2-.4-.4-.4z"/>
                          <path fill="#F9B86A" d="M13 14.9h-1.2c.2 0 .4.2.4.4v1.1c0 .2-.2.4-.4.4H13c.2 0 .4-.2.4-.4v-1.2c0-.1-.2-.3-.4-.3z"/>
                          <g fill="#7055A0" transform="translate(5 15)">
                            <circle cx="1" cy=".9" r="1"/>
                            <circle cx="2.2" cy=".9" r="1"/>
                            <circle cx="3.4" cy=".9" r="1"/>
                            <circle cx="4.6" cy=".9" r="1"/>
                            <circle cx="5.9" cy=".9" r="1"/>
                            <circle cx="7.1" cy=".9" r="1"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                    
                                      </span>);
                    }}
            
            
            starCount={5}
            value={data.strapiRezension.bewertung}
            
          />
  </div>
</div>






    </Layout>
)

export default RezensionTemplate

export const query = graphql`  
  query RezensionTemplate($slug: String!) {
    strapiRezension(slug: { eq: $slug }) {
        titel
        slug
        erschienen
        genre
        verlag
        klappentext
        rezension
        seiten
        bewertung
        amazon
        hugendubel
        buchformat
        tahlia
        autor {
            slug
            autorname
          }
        coverimg {
            childImageSharp {
            fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid
            }
            }
        }
    }
  }
`